import { AnyStateMachine, StateFrom } from 'xstate'

import { isPageAccessedByReload } from './helpers'
import { getSessionStorageItem } from './storage'

export const getInitialState = <TMachine extends AnyStateMachine>(
  sessionStorageKey: string,
  shouldHydrateState: (state: StateFrom<TMachine>) => boolean,
): StateFrom<TMachine> => {
  const persistedState = getSessionStorageItem(sessionStorageKey)
  const hydratedState = persistedState ? JSON.parse(persistedState) : undefined

  const shouldUseHydratedState = isPageAccessedByReload || shouldHydrateState(hydratedState)

  return shouldUseHydratedState ? hydratedState : undefined
}
