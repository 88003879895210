// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
const isStorageAvailable = (type: 'localStorage' | 'sessionStorage'): boolean => {
  let storage
  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return !!(
      e instanceof DOMException &&
      // everything except Firefox
      (e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

export const getSessionStorageItem = <K extends string, T extends string>(key: K): T | null => {
  if (isStorageAvailable('sessionStorage')) {
    return <T>sessionStorage.getItem(key)
  }
  return null
}

export const getLocalStorageItem = <K extends string, T extends string>(key: K): T | null => {
  if (isStorageAvailable('localStorage')) {
    return <T>localStorage.getItem(key)
  }
  return null
}

export const setSessionStorageItem = <K extends string, T extends string>(key: K, value: T): void => {
  if (isStorageAvailable('sessionStorage')) {
    sessionStorage.setItem(key, value)
  }
}

export const setLocalStorageItem = <K extends string, T extends string>(key: K, value: T): void => {
  if (isStorageAvailable('localStorage')) {
    localStorage.setItem(key, value)
  }
}

export const removeSessionStorageItem = <K extends string>(key: K): void => {
  if (isStorageAvailable('sessionStorage')) {
    sessionStorage.removeItem(key)
  }
}

export const removeLocalStorageItem = <K extends string>(key: K): void => {
  if (isStorageAvailable('localStorage')) {
    localStorage.removeItem(key)
  }
}

export const clearSessionStorage = (): void => {
  if (isStorageAvailable('sessionStorage')) {
    sessionStorage.clear()
  }
}

export const clearLocalStorage = (): void => {
  if (isStorageAvailable('localStorage')) {
    localStorage.clear()
  }
}
